// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-6-powodow-dla-ktorych-warto-zamienic-excela-system-crm-js": () => import("./../../../src/pages/6-powodow-dla-ktorych-warto-zamienic-excela-system-crm.js" /* webpackChunkName: "component---src-pages-6-powodow-dla-ktorych-warto-zamienic-excela-system-crm-js" */),
  "component---src-pages-aplikacja-crm-js": () => import("./../../../src/pages/aplikacja-crm.js" /* webpackChunkName: "component---src-pages-aplikacja-crm-js" */),
  "component---src-pages-baza-wiedzy-js": () => import("./../../../src/pages/baza-wiedzy.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-js" */),
  "component---src-pages-bezpieczenstwo-danych-js": () => import("./../../../src/pages/bezpieczenstwo-danych.js" /* webpackChunkName: "component---src-pages-bezpieczenstwo-danych-js" */),
  "component---src-pages-budowa-kanalu-sprzedazy-posredniej-js": () => import("./../../../src/pages/budowa-kanalu-sprzedazy-posredniej.js" /* webpackChunkName: "component---src-pages-budowa-kanalu-sprzedazy-posredniej-js" */),
  "component---src-pages-crm-cennik-js": () => import("./../../../src/pages/crm-cennik.js" /* webpackChunkName: "component---src-pages-crm-cennik-js" */),
  "component---src-pages-crm-dla-duzej-firmy-js": () => import("./../../../src/pages/crm-dla-duzej-firmy.js" /* webpackChunkName: "component---src-pages-crm-dla-duzej-firmy-js" */),
  "component---src-pages-crm-dla-firm-js": () => import("./../../../src/pages/crm-dla-firm.js" /* webpackChunkName: "component---src-pages-crm-dla-firm-js" */),
  "component---src-pages-crm-dla-malej-firmy-js": () => import("./../../../src/pages/crm-dla-malej-firmy.js" /* webpackChunkName: "component---src-pages-crm-dla-malej-firmy-js" */),
  "component---src-pages-crm-dla-malych-firm-js": () => import("./../../../src/pages/crm-dla-malych-firm.js" /* webpackChunkName: "component---src-pages-crm-dla-malych-firm-js" */),
  "component---src-pages-crm-dla-sredniej-firmy-js": () => import("./../../../src/pages/crm-dla-sredniej-firmy.js" /* webpackChunkName: "component---src-pages-crm-dla-sredniej-firmy-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-crm-online-js": () => import("./../../../src/pages/crm-online.js" /* webpackChunkName: "component---src-pages-crm-online-js" */),
  "component---src-pages-crm-w-chmurze-js": () => import("./../../../src/pages/crm-w-chmurze.js" /* webpackChunkName: "component---src-pages-crm-w-chmurze-js" */),
  "component---src-pages-dane-osobowe-w-crm-js": () => import("./../../../src/pages/dane-osobowe-w-crm.js" /* webpackChunkName: "component---src-pages-dane-osobowe-w-crm-js" */),
  "component---src-pages-demo-crm-js": () => import("./../../../src/pages/demo-crm.js" /* webpackChunkName: "component---src-pages-demo-crm-js" */),
  "component---src-pages-demo-dziekujemy-js": () => import("./../../../src/pages/demo-dziekujemy.js" /* webpackChunkName: "component---src-pages-demo-dziekujemy-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dlaczego-program-crm-js": () => import("./../../../src/pages/dlaczego-program-crm.js" /* webpackChunkName: "component---src-pages-dlaczego-program-crm-js" */),
  "component---src-pages-dobry-crm-js": () => import("./../../../src/pages/dobry-crm.js" /* webpackChunkName: "component---src-pages-dobry-crm-js" */),
  "component---src-pages-faq-rodo-js": () => import("./../../../src/pages/faq-rodo.js" /* webpackChunkName: "component---src-pages-faq-rodo-js" */),
  "component---src-pages-filozofia-crm-js": () => import("./../../../src/pages/filozofia-crm.js" /* webpackChunkName: "component---src-pages-filozofia-crm-js" */),
  "component---src-pages-funkcje-systemu-crm-js": () => import("./../../../src/pages/funkcje-systemu-crm.js" /* webpackChunkName: "component---src-pages-funkcje-systemu-crm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-dziala-system-crm-js": () => import("./../../../src/pages/jak-dziala-system-crm.js" /* webpackChunkName: "component---src-pages-jak-dziala-system-crm-js" */),
  "component---src-pages-jak-wygrywac-wiecej-szans-sprzedazy-wykorzystujac-system-crm-js": () => import("./../../../src/pages/jak-wygrywac-wiecej-szans-sprzedazy-wykorzystujac-system-crm.js" /* webpackChunkName: "component---src-pages-jak-wygrywac-wiecej-szans-sprzedazy-wykorzystujac-system-crm-js" */),
  "component---src-pages-jak-zaplanowac-proces-sprzedazy-js": () => import("./../../../src/pages/jak-zaplanowac-proces-sprzedazy.js" /* webpackChunkName: "component---src-pages-jak-zaplanowac-proces-sprzedazy-js" */),
  "component---src-pages-jaki-crm-js": () => import("./../../../src/pages/jaki-crm.js" /* webpackChunkName: "component---src-pages-jaki-crm-js" */),
  "component---src-pages-kalendarz-i-zadania-js": () => import("./../../../src/pages/kalendarz-i-zadania.js" /* webpackChunkName: "component---src-pages-kalendarz-i-zadania-js" */),
  "component---src-pages-kartoteki-kontrahentow-js": () => import("./../../../src/pages/kartoteki-kontrahentow.js" /* webpackChunkName: "component---src-pages-kartoteki-kontrahentow-js" */),
  "component---src-pages-komunikator-js": () => import("./../../../src/pages/komunikator.js" /* webpackChunkName: "component---src-pages-komunikator-js" */),
  "component---src-pages-kontakt-dziekujemy-js": () => import("./../../../src/pages/kontakt-dziekujemy.js" /* webpackChunkName: "component---src-pages-kontakt-dziekujemy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-materialy-video-js": () => import("./../../../src/pages/materialy-video.js" /* webpackChunkName: "component---src-pages-materialy-video-js" */),
  "component---src-pages-mity-o-crm-js": () => import("./../../../src/pages/mity-o-crm.js" /* webpackChunkName: "component---src-pages-mity-o-crm-js" */),
  "component---src-pages-mobilny-crm-js": () => import("./../../../src/pages/mobilny-crm.js" /* webpackChunkName: "component---src-pages-mobilny-crm-js" */),
  "component---src-pages-mobilny-crm-sprzedaz-js": () => import("./../../../src/pages/mobilny-crm-sprzedaz.js" /* webpackChunkName: "component---src-pages-mobilny-crm-sprzedaz-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oprogramowanie-crm-js": () => import("./../../../src/pages/oprogramowanie-crm.js" /* webpackChunkName: "component---src-pages-oprogramowanie-crm-js" */),
  "component---src-pages-pierwsze-kroki-w-crm-js": () => import("./../../../src/pages/pierwsze-kroki-w-crm.js" /* webpackChunkName: "component---src-pages-pierwsze-kroki-w-crm-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-pomoc-dynamiczny-pulpit-js": () => import("./../../../src/pages/pomoc/dynamiczny-pulpit.js" /* webpackChunkName: "component---src-pages-pomoc-dynamiczny-pulpit-js" */),
  "component---src-pages-pomoc-import-danych-js": () => import("./../../../src/pages/pomoc/import-danych.js" /* webpackChunkName: "component---src-pages-pomoc-import-danych-js" */),
  "component---src-pages-pomoc-js": () => import("./../../../src/pages/pomoc.js" /* webpackChunkName: "component---src-pages-pomoc-js" */),
  "component---src-pages-pomoc-kontrahenci-js": () => import("./../../../src/pages/pomoc/kontrahenci.js" /* webpackChunkName: "component---src-pages-pomoc-kontrahenci-js" */),
  "component---src-pages-pomoc-lista-aktywnosci-js": () => import("./../../../src/pages/pomoc/lista-aktywnosci.js" /* webpackChunkName: "component---src-pages-pomoc-lista-aktywnosci-js" */),
  "component---src-pages-pomoc-operacje-masowe-js": () => import("./../../../src/pages/pomoc/operacje-masowe.js" /* webpackChunkName: "component---src-pages-pomoc-operacje-masowe-js" */),
  "component---src-pages-pomoc-profil-pracownika-js": () => import("./../../../src/pages/pomoc/profil-pracownika.js" /* webpackChunkName: "component---src-pages-pomoc-profil-pracownika-js" */),
  "component---src-pages-pomoc-szanse-sprzedazy-js": () => import("./../../../src/pages/pomoc/szanse-sprzedazy.js" /* webpackChunkName: "component---src-pages-pomoc-szanse-sprzedazy-js" */),
  "component---src-pages-pomoc-zadania-i-zdarzenia-js": () => import("./../../../src/pages/pomoc/zadania-i-zdarzenia.js" /* webpackChunkName: "component---src-pages-pomoc-zadania-i-zdarzenia-js" */),
  "component---src-pages-prezentacja-crm-js": () => import("./../../../src/pages/prezentacja-crm.js" /* webpackChunkName: "component---src-pages-prezentacja-crm-js" */),
  "component---src-pages-proces-budowy-sieci-stalych-odbiorcow-koncowych-w-crm-js": () => import("./../../../src/pages/proces-budowy-sieci-stalych-odbiorcow-koncowych-w-crm.js" /* webpackChunkName: "component---src-pages-proces-budowy-sieci-stalych-odbiorcow-koncowych-w-crm-js" */),
  "component---src-pages-proces-pozyskiwania-dystrybutorow-js": () => import("./../../../src/pages/proces-pozyskiwania-dystrybutorow.js" /* webpackChunkName: "component---src-pages-proces-pozyskiwania-dystrybutorow-js" */),
  "component---src-pages-proces-sprzedazy-projektowej-js": () => import("./../../../src/pages/proces-sprzedazy-projektowej.js" /* webpackChunkName: "component---src-pages-proces-sprzedazy-projektowej-js" */),
  "component---src-pages-proces-sprzedazy-w-crm-js": () => import("./../../../src/pages/proces-sprzedazy-w-crm.js" /* webpackChunkName: "component---src-pages-proces-sprzedazy-w-crm-js" */),
  "component---src-pages-program-crm-js": () => import("./../../../src/pages/program-crm.js" /* webpackChunkName: "component---src-pages-program-crm-js" */),
  "component---src-pages-program-dla-malej-firmy-js": () => import("./../../../src/pages/program-dla-malej-firmy.js" /* webpackChunkName: "component---src-pages-program-dla-malej-firmy-js" */),
  "component---src-pages-prosty-crm-dla-handlowca-js": () => import("./../../../src/pages/prosty-crm-dla-handlowca.js" /* webpackChunkName: "component---src-pages-prosty-crm-dla-handlowca-js" */),
  "component---src-pages-pulpit-pracownika-js": () => import("./../../../src/pages/pulpit-pracownika.js" /* webpackChunkName: "component---src-pages-pulpit-pracownika-js" */),
  "component---src-pages-raporty-js": () => import("./../../../src/pages/raporty.js" /* webpackChunkName: "component---src-pages-raporty-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-skuteczny-proces-sprzedazy-js": () => import("./../../../src/pages/skuteczny-proces-sprzedazy.js" /* webpackChunkName: "component---src-pages-skuteczny-proces-sprzedazy-js" */),
  "component---src-pages-sprzedaz-w-crm-js": () => import("./../../../src/pages/sprzedaz-w-crm.js" /* webpackChunkName: "component---src-pages-sprzedaz-w-crm-js" */),
  "component---src-pages-system-crm-js": () => import("./../../../src/pages/system-crm.js" /* webpackChunkName: "component---src-pages-system-crm-js" */),
  "component---src-pages-system-crm-wersja-blog-js": () => import("./../../../src/pages/system-crm-wersja-blog.js" /* webpackChunkName: "component---src-pages-system-crm-wersja-blog-js" */),
  "component---src-pages-umowa-powierzenia-js": () => import("./../../../src/pages/umowa-powierzenia.js" /* webpackChunkName: "component---src-pages-umowa-powierzenia-js" */),
  "component---src-pages-ups-js": () => import("./../../../src/pages/ups.js" /* webpackChunkName: "component---src-pages-ups-js" */),
  "component---src-pages-usprawnianie-procesu-sprzedazy-js": () => import("./../../../src/pages/usprawnianie-procesu-sprzedazy.js" /* webpackChunkName: "component---src-pages-usprawnianie-procesu-sprzedazy-js" */),
  "component---src-pages-wiedza-o-crm-js": () => import("./../../../src/pages/wiedza-o-crm.js" /* webpackChunkName: "component---src-pages-wiedza-o-crm-js" */),
  "component---src-pages-zaloguj-crm-js": () => import("./../../../src/pages/zaloguj-crm.js" /* webpackChunkName: "component---src-pages-zaloguj-crm-js" */),
  "component---src-pages-zarzadzanie-uprawnieniami-js": () => import("./../../../src/pages/zarzadzanie-uprawnieniami.js" /* webpackChunkName: "component---src-pages-zarzadzanie-uprawnieniami-js" */)
}

